import React from "react"
import { Container, Row, Col, ListGroup, ListGroupItem } from "react-bootstrap"

import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/LayoutComponent"
import netlifyStyle from "../../site/settings/style.json"
import Image from "../components/CloudinaryImage"
import BackgroundImage from "../components/BackgroundImg"
import SEO from "../components/seo"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faBusinessTime,
  faHandsHelping,
  faUserCheck,
} from "@fortawesome/free-solid-svg-icons"

const iconStyle = {
  color: netlifyStyle.brand_color,
}

function About() {
  const data = useStaticQuery(graphql`
    query {
      aboutPage: allFile(filter: { base: { eq: "about.md" } }) {
        edges {
          node {
            absolutePath
            relativeDirectory
            childMarkdownRemark {
              frontmatter {
                title
                main_heading
                description
                image_section {
                  img_1
                  img_2
                  img_3
                  img_4
                  img_5
                  img_6
                }
                point_1
                point_2
                point_3
                heading_1
                paragraph_1
                block_1_img
                heading_2
                paragraph_2

                list_item_1
                list_item_2
                list_item_3

                date
              }
            }
          }
        }
      }
    }
  `)

  const customColStyle = {
    minHeight: "10.687rem",
    maxHeight: "12rem",
  }

  const imgStyle = {
    width: "70%",
    height: "100%",
  }
  const img2Style = {
    width: "60%",
    height: "80%",
    marginBottom: "2rem",
  }
  const aboutContent =
    data.aboutPage.edges[0].node.childMarkdownRemark.frontmatter
  return (
    <Layout>
      <SEO
        title="About DNA HVAC"
        description="DNA HVAC is a heating and cooling contractor based out of Las Vegas Nevada. DNA offers a variety of services for both Residential and Commercial"
        keywords="DNA HVAC, Clark County, Las Vegas, AC Las Vegas, About DNA"
      />
      <Container className="my-5 p-4">
        <h2
          data-sal="slide-up"
          data-sal-delay="300"
          data-sal-easing="ease"
          className="text-center mb-4 "
        >
          {aboutContent.main_heading}
        </h2>
        <Row data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease">
          <Col style={customColStyle} xs={3} className=" px-0 ">
            <BackgroundImage markdownLink={aboutContent.image_section.img_1}>
              <div style={{ minHeight: "10rem" }}></div>
            </BackgroundImage>
          </Col>
          <Col style={customColStyle} xs={5} className="px-0 mx-1">
            <BackgroundImage markdownLink={aboutContent.image_section.img_2}>
              {" "}
              <div style={{ minHeight: "10rem" }}></div>
            </BackgroundImage>
          </Col>
          <Col className="px-0 " style={customColStyle}>
            <BackgroundImage markdownLink={aboutContent.image_section.img_3}>
              {" "}
              <div style={{ minHeight: "10rem" }}></div>
            </BackgroundImage>
          </Col>
        </Row>
        <Row
          data-sal="slide-left"
          data-sal-delay="300"
          data-sal-easing="ease"
          className="mb-5 "
        >
          <Col xs={4} className="px-0  " style={customColStyle}>
            <BackgroundImage markdownLink={aboutContent.image_section.img_4}>
              {" "}
              <div style={{ minHeight: "10rem" }}></div>
            </BackgroundImage>
          </Col>
          <Col xs={5} className=" px-0 mx-1" style={customColStyle}>
            <BackgroundImage markdownLink={aboutContent.image_section.img_5}>
              {" "}
              <div style={{ minHeight: "10rem" }}></div>
            </BackgroundImage>
          </Col>
          <Col className="  px-0" style={customColStyle}>
            <BackgroundImage markdownLink={aboutContent.image_section.img_6}>
              {" "}
              <div style={{ minHeight: "10rem" }}></div>
            </BackgroundImage>
          </Col>
        </Row>
        <Row className="mb-4 mt-3 text-center">
          <Col data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease">
            <FontAwesomeIcon
              className="fa-3x mb-1 "
              icon={faBusinessTime}
              style={iconStyle}
            />
            <p className="font-weight-bold">{aboutContent.point_1}</p>
          </Col>
          <Col
            data-sal="slide-down"
            data-sal-delay="300"
            data-sal-easing="ease"
          >
            <FontAwesomeIcon
              className="fa-3x mb-1 "
              icon={faHandsHelping}
              style={iconStyle}
            />
            <p className="font-weight-bold">{aboutContent.point_2}</p>
          </Col>
          <Col data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease">
            <FontAwesomeIcon
              className="fa-3x mb-1 "
              style={iconStyle}
              icon={faUserCheck}
            />
            <p className="font-weight-bold">{aboutContent.point_3}</p>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col xs={12} lg={7}>
            <h4 data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease">
              {aboutContent.heading_1}
            </h4>
            <p data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease">
              {aboutContent.paragraph_1}
            </p>
          </Col>
          <Col data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease">
            <BackgroundImage
              style={imgStyle}
              markdownLink={aboutContent.block_1_img}
            >
              {" "}
              <div style={{ minHeight: "10rem" }}></div>{" "}
            </BackgroundImage>
          </Col>
        </Row>

        {/*
           //! Add service area map
        <Row>
          <Col>
            <h5>{aboutContent.heading_2}</h5>
            <p className="m-0">{aboutContent.paragraph_1}</p>
          </Col>

          <Col className="col-7 d-lg-block d-none">
            <ListGroup>
              <ListGroupItem>{aboutContent.list_item_1}</ListGroupItem>
              <ListGroupItem>{aboutContent.list_item_2}</ListGroupItem>
              <ListGroupItem>{aboutContent.list_item_3}</ListGroupItem>
            </ListGroup>
          </Col>
        </Row> */}
      </Container>
    </Layout>
  )
}

export default About
